// @flow

import * as React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';
import * as constants from '../../utils/constants';

type IconProps = {
  /** Specify a unique id for the HTML element. */
  id?: string,
  /** The class attribute specifies one or more classnames for an element. */
  className?: string,
  /** Set the margin-left. [$Values&lt;typeof ICON_SIZE&gt;] */
  size?: $Values<typeof constants.ICON_SIZE>,
  /** Set the icon color. [$Values&lt;typeof COLOR&gt;] */
  color?: $Values<typeof constants.COLOR>,
  /** Set the icon variant. [$Values&lt;typeof ICON_VARIANT&gt;] */
  variant: $Values<typeof constants.ICON_VARIANT>
};

type StyledIconProps = {
  id?: string,
  className?: string,
  size: $Values<typeof constants.ICON_SIZE>,
  color: $Values<typeof constants.COLOR>
};

const Icon = ({
  id,
  className,
  color = constants.COLOR.BLACK,
  size = constants.ICON_SIZE.MD,
  variant
}: IconProps) => {
  const props: StyledIconProps = { id, className, color, size };
  // eslint-disable-next-line import/no-dynamic-require
  const Svg = require(`./svg/${variant}.svg`);
  const StyledSvg: ReactComponentStyled<StyledIconProps> = styled(Svg)`
    ${({ size, color }: StyledIconProps) => `
      width: ${size};
      height: ${size};
      fill: none;
      stroke: ${color};
      stroke-width: 3px;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      stroke-linejoin: round;
    `}
  `;

  return <StyledSvg {...props} />;
};

export default Icon;
